import { zipObject } from "lodash"
import { defaultDateFormat, defaultDateTimeFormat } from "../constants"
import dayJs, { Dayjs } from "../libs/dayjs"

export const toDayJs = (date?: string | Dayjs, sourceFormatter?: string) => {
  return typeof date === "string" ? dayJs(date, sourceFormatter) : date
}

export const toDate = (
  date?: string | Dayjs,
  formatter = defaultDateFormat,
  sourceFormatter?: string,
) => {
  const _date = toDayJs(date, sourceFormatter)
  if (!_date) return null
  if (_date.isValid()) {
    return _date.format(formatter)
  } else {
    return "-"
  }
}

export const toDateTime = (
  date?: string | Dayjs,
  formatter = defaultDateTimeFormat,
  sourceFormatter?: string,
) => {
  const _date = toDayJs(date, sourceFormatter)
  if (!_date) return null
  if (_date.isValid()) {
    return _date.format(formatter)
  } else {
    return "-"
  }
}

export const toISOtime = (date?: string | Dayjs) => {
  const _date = toDayJs(date)
  if (!_date) return null
  if (_date.isValid()) {
    return _date.toISOString()
  } else {
    return null
  }
}

export const toLabelValue = <T extends Partial<Record<keyof T, string>>>(
  records: T,
) => {
  return Object.entries(records).map(([key, value]) => {
    return { value: key, label: value as string }
  })
}

export const arrayToLabelValue = (list: Array<number | string>) => {
  return list.map((value) => {
    return { value, label: value.toString() }
  })
}

const NumberFormat = (value: number, digits: number) => {
  return new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
}
export const ParseNumber = (value: number | string) =>
  typeof value === "number"
    ? isNaN(value)
      ? 0
      : value
    : (value && Number(value.replace(/,/g, ""))) || 0

export const ToNumber = (value: number, digits = 2, isRound = false) => {
  const num = ParseNumber(value)
  const adj = Math.pow(10, digits)
  const floor = Math.floor(num * adj) / adj
  const round = Math.round(num * adj) / adj
  let decimal = digits
  let formatted = NumberFormat(isRound ? round : floor, decimal)
  if (value > 0 && Number(formatted) === 0) {
    decimal += 1
    formatted = ToNumber(value, decimal, isRound)
  }
  return formatted
}

export const isInEnum = <T extends object>(value: string, enumObject: T) => {
  return Object.values(enumObject).includes(value)
}

export const stringToLabelValue = (value: string[]) => {
  return toLabelValue(zipObject(value, value))
}

export const toErrorMessage = (error: string) => {
  return error.replace("[GraphQL] ", "")
}
