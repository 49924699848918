export const REQUIRED_MESSAGE = "Required"
export const INVALID_MESSAGE = "Invalid"
export const ENTER_VALID_VALUE_MESSAGE = "Enter a valid value"
export const TIME_IN_PAST_MESSAGE =
  "Please select a date that is not in the past"
export const TIME_IN_FUTURE_MESSAGE =
  "Please select a date that is not in the future"

export const MaxLengthError = (max: number) => `Maximum length is ${max}`
export const MAX_LENGtH_10 = MaxLengthError(10)

export const PROMPT_MESSAGE_FOR_QUANTITY_REASON =
  "You have reached the maximum number of products you can add to this request"

export const PROMPT_MESSAGE_FOR_REQUIRED_FIELDS =
  "Please enter a request name and format for shipping before adding product"
