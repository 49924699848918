import zod from "zod"
import { REQUIRED_MESSAGE } from "../constants/messages"
import { toDayJs } from "../convertor"
import dayJs, { Dayjs } from "../libs/dayjs"
import { alphanumeric, alphanumericAndSpecialCharacters } from "../constants"


type NullishString = string | null | undefined

type OnFlattenedErrorFound = (
  errors: Record<string, string>,
  matches: string[],
  keyFound: string,
  valueFound: string,
) => void

const strictString = zod.string().transform((s: NullishString) => s || "")

export const dayjsInstance = zod.instanceof(dayJs as unknown as typeof Dayjs, {
  message: REQUIRED_MESSAGE,
})

export const dateInput = zod
  .string({
    // this is to handle possible null value and to make the error message consistent otherwise it will show 'Expected string, received null' or something like that
    message: REQUIRED_MESSAGE,
  })
  .datetime()
  .transform((v: string) => {
    return v && toDayJs(v)
  })

export const dateOutput = dayjsInstance
  .refine((v) => v.isValid(), { message: "Invalid date" })
  .transform((v: Dayjs) => {
    return v?.toISOString()
  })

export const stringToBoolean = zod.string({ message: REQUIRED_MESSAGE }).transform((v) => {
  if (v === 'true') {
    return true;
  }
  else if (v === "false") {
    return false;
  } else {
    return undefined;
  }
})

export const stringToNull = zod.string({ message: REQUIRED_MESSAGE }).transform((v) => {
  if (v === 'null') {
    return null;
  }
  return undefined
})

export const booleanToString = zod.boolean().transform((v) => {
  if (v === true) {
    return 'true';
  }
  else if (v === false) {
    return 'false';
  } else {
    return undefined;
  }
})

export const nonEmptyString = strictString.refine((s: string) => s.length > 0, {
  message: REQUIRED_MESSAGE,
})

export const optionalString = strictString.nullish()

export function toggleStringSchema(isDraft?: boolean) {
  return isDraft ? optionalString : nonEmptyString
}

export function toggleSchema(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: zod.ZodSchema<any>,
  isOptianal?: boolean,
  excludingNull = false,
) {
  return isOptianal
    ? excludingNull
      ? schema.optional()
      : schema.nullish()
    : schema
}

export function flattenValues(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  prefix = "",
): Record<string, string> {
  const separator = "."
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + separator : ""
    if (typeof obj[k] === "object" && obj[k] !== null) {
      Object.assign(acc, flattenValues(obj[k], pre + k))
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc as Record<string, any>)[pre + k] = obj[k]
    }
    return acc
  }, {})
}

export function getErrorCount(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationErrors: Record<string, any>,
  errorKey: string,
  onErrorFound?: OnFlattenedErrorFound,
) {
  let count = 0
  const errors = flattenValues(validationErrors)
  Object.entries(errors).forEach(([key, value]) => {
    // eslint-disable-next-line no-useless-escape
    const pattern = new RegExp(`^([0-9a-z\.]*)\.(${errorKey})$`, "i")
    const matches = key.match(pattern)
    if (matches?.length) {
      if (onErrorFound) {
        onErrorFound(errors, matches, key, value)
      }
      count++
    }
  })
  return count
}

export function isSchemaFieldOptional(
  schema: zod.Schema | undefined,
  fieldPath: string,
): boolean | undefined {
  if (!schema || !fieldPath) {
    return undefined
  }

  const filteredFieldPath = removeArrayIndexFromFieldName(fieldPath) as string
  const fieldNames = filteredFieldPath.split(".")

  // This is for testing a particular field
  // if (
  //   fieldPath !=
  //   "consignment.mainCarriageTransportMovements.0.registeredCountry.id"
  // ) {
  //   return undefined
  // }

  const fieldSchema: zod.Schema = getFieldSchema(
    schema,
    fieldNames,
    fieldNames[0],
  ) as unknown as zod.Schema

  if (!fieldSchema) {
    console.error("filed schema not exist ", fieldPath)
  } else {
    // console.log("fieldSchema", fieldPath, fieldSchema, fieldSchema.isOptional())
  }

  return fieldSchema?.isOptional() || fieldSchema?.isNullable()
}

export function getFieldOptionalMap(
  fieldNameMap: Record<string, string>,
  schema: zod.ZodSchema,
): Record<string, boolean> {
  const fieldOptionalMap = {} as Record<string, boolean>
  for (const fieldPath of Object.values(fieldNameMap)) {
    fieldOptionalMap[fieldPath] = isSchemaFieldOptional(
      schema,
      fieldPath,
    ) as boolean
  }
  return fieldOptionalMap
}

export function getFieldNameMap(defaultValueMap: Record<string, string[]>): Record<string, string> {
  const fieldNameMap: Record<string, string> = {}
  for (const key in defaultValueMap) {
    fieldNameMap[key] = normalizeFieldName(defaultValueMap[key][0]) as string
  }
  return fieldNameMap;
}

export function getHiddenFields(
  allFields: string[],
  visibleFields: string[],
): string[] {
  // console.log("all and visible fields", allFields, visibleFields)
  return allFields.filter((field) => !visibleFields.includes(field))
}

export function normalizeFieldName(fieldName?: string) {
  return fieldName ? fieldName.replaceAll(/\[([0-9]+)\]\./g, ".$1.") : fieldName
}

export function removeArrayIndexFromFieldName(fieldName?: string) {
  return fieldName
    ? normalizeFieldName(fieldName)?.replaceAll(/\.[0-9]+\./g, ".")
    : fieldName
}

export function getFieldSchema(
  schema: zod.Schema,
  fieldNames: string[],
  fieldName: string,
): zod.Schema | undefined {
  // console.log("getFieldSchema ", schema, fieldNames, fieldName)
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) {
    return undefined
  }
  // check if schema is nullable or optional
  if (schema instanceof zod.ZodNullable || schema instanceof zod.ZodOptional) {
    // console.log("unwraped ", fieldName, schema, schema.unwrap())
    return getFieldSchema(schema.unwrap(), fieldNames, fieldName)
  }
  // ZodEffects are generated by refine function
  if (schema instanceof zod.ZodEffects) {
    // console.log(
    //   "instanceof zod.ZodEffects",
    //   fieldName,
    //   (schema._def as any).schema,
    // )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentSchema = (schema._def as any).schema
    return getFieldSchema(currentSchema, fieldNames, fieldName)
  }
  // check if schema is an array
  if (schema instanceof zod.ZodArray) {
    return getFieldSchema(schema.element, fieldNames, fieldName)
  }
  // check if schema is an object, only when it is object then it progress to next field
  if (schema instanceof zod.ZodObject) {
    // console.log("instance of object", fieldName, schema)
    const fieldPosition = fieldNames.indexOf(fieldName)
    const isLast = fieldPosition === fieldNames.length - 1
    const currentSchema = schema.shape[fieldName]
    if (isLast) {
      if (!currentSchema) {
        // it normally happens when the previous step is trying getting a sub schema object that is wrapped in ZodEffects (meaning the object has refine or superRefine attached)
        return schema
      }
      if (currentSchema instanceof zod.ZodEffects) {
        // console.log("instance of effects", fieldName, currentSchema)
        return getFieldSchema(currentSchema._def.schema, fieldNames, fieldName)
      }
      return currentSchema
    } else {
      return getFieldSchema(
        currentSchema,
        fieldNames,
        fieldNames[fieldPosition + 1],
      )
    }
  }

  // if(schema instanceof zod.ZodString || schema instanceof zod.ZodNumber){

  // }

  // if schema is string, number and other primitive type then we have reached bottom return
  return schema
}

export function matchArrayFieldName(fieldName: string): string[] {
  return fieldName.match(/^([a-z0-9.]+)(\.[0-9]+\.)([a-z0-9.]+)$/i) || []
}

export function unpackSchemaObject(packedSchema: zod.ZodSchema) {
  let unpackedSchema = packedSchema
  if (
    packedSchema instanceof zod.ZodOptional ||
    packedSchema instanceof zod.ZodNullable
  ) {
    unpackedSchema = packedSchema.unwrap()
  } else if (packedSchema instanceof zod.ZodArray) {
    unpackedSchema = packedSchema.element
  } else if (packedSchema instanceof zod.ZodEffects) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unpackedSchema = (packedSchema._def as any).schema;
  }

  return unpackedSchema
}

export const customAlphanumericString = (maxChar = 255, isAlphanumeric = true) =>
  zod
    .string()
    .max(maxChar)
    .regex(isAlphanumeric ? alphanumeric : alphanumericAndSpecialCharacters)
