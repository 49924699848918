"use client"
import { querySfExporter } from "@tc/ui-dfe/query"
import { pipePromises, removeTypename } from "@tc/ui-dfe/utils/helper"
import { initClient, ssr } from "@tc/ui-dfe/utils/libs/urql/exchanges"
import { Session } from "@tc/ui-dfe/utils/types"
import { usePermission } from "@tc/ui-permission/hooks/usePermission"
import { ROLE } from "@tc/ui-permission/utils"
import { UrqlProvider, useClient } from "@urql/next"
import { SessionProvider, signOut, useSession } from "next-auth/react"
import { useRouter } from "next/navigation"
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
export interface AuthProviderProps {
  children: React.ReactNode
  APP_SYNC_URL: string
}
interface AuthUser {
  id?: string
  roles?: string[]
  partyId?: string
  name?: string
  nzbn?: string | null
  addresses?: {
    addressType?: string
    city?: string
    country?: string
    street?: string
    postalCode?: string
    isPrimary?: boolean
  }[]
  contacts?: {
    role?: string | null
    name?: string
    emails?: {
      emailAddress?: string
      isPrimary?: boolean
    }[]
    phones?: {
      telephoneNumber?: string
      isPrimary?: boolean
    }[]
  }[]
  identifiers?: {
    id?: string
    value?: string
    description?: string
  }[]
}
interface AuthUserProviderProps {
  children: React.ReactNode
}
interface AuthUserContextProps {
  authUser?: AuthUser
}

const getAuthSession = async () => {
  const session = await fetch("/api/auth/session")
    .then((res) => res.json())
    .catch(() => null)
  return session
}

const createClient = initClient(getAuthSession, signOut)
export const UrqlClientProvider = ({
  children,
  APP_SYNC_URL,
}: AuthProviderProps) => {
  const client = useMemo(() => {
    return createClient(APP_SYNC_URL)
  }, [])
  return (
    <UrqlProvider client={client} ssr={ssr}>
      {children}
    </UrqlProvider>
  )
}

const getPrimaryAuthUserInfo = (authUser: AuthUser) => {
  const { addresses, contacts, identifiers } = authUser
  const _addresses = addresses?.filter((add) => add.isPrimary)
  const emails = contacts?.[0]?.emails?.filter((email) => email.isPrimary)
  const phones = contacts?.[0]?.phones?.filter((phone) => phone.isPrimary)
  const _identifiers = identifiers?.[0] //TODO: need to update this
  return {
    ...authUser,
    identifiers: [_identifiers],
    addresses: _addresses,
    contacts: [
      {
        ...authUser?.contacts?.[0],
        emails,
        phones,
      },
    ],
  } as AuthUser
}
const AuthUserContext = createContext<AuthUserContextProps>({})
export const AuthUserProvider = ({ children }: AuthUserProviderProps) => {
  const [authUser, setAuthUser] = useState<AuthUser>()
  const apiClient = useClient()
  const { allowRole } = usePermission()
  const router = useRouter()
  const session = useSession({
    required: true,
    onUnauthenticated() {
      router.push("/signin")
    },
  })
  const loadSfExporter = useCallback(async (_authUser: AuthUser) => {
    if (_authUser.partyId && allowRole([ROLE.ManageProducts])) {
      const result = await apiClient.query(querySfExporter.toString(), {
        id: _authUser.partyId,
      })
      if (!result?.data?.sfExporter) {
        return _authUser
      }
      return {
        ..._authUser,
        ...getPrimaryAuthUserInfo(
          removeTypename(result?.data?.sfExporter as object),
        ),
      }
    }
    return _authUser
  }, [])
  const handleResponse = useCallback(async (_authUser: AuthUser) => {
    setAuthUser(_authUser)
  }, [])
  const initAuthUser = useCallback(async (_authUser: AuthUser) => {
    try {
      await pipePromises(loadSfExporter, handleResponse)(_authUser)
    } catch (error) {
      console.error("Error loading auth user", error)
    }
  }, [])
  useEffect(() => {
    const initUser = {
      roles: (session?.data as Session)?.user?.roles,
      partyId: (session?.data as Session)?.user?.party_id,
    }
    initAuthUser(initUser)
  }, [(session?.data as Session)?.user?.party_id])
  return (
    <AuthUserContext.Provider value={{ authUser }}>
      {children}
    </AuthUserContext.Provider>
  )
}
export const AuthProvider = memo(
  ({ children, APP_SYNC_URL }: AuthProviderProps) => {
    return (
      <UrqlClientProvider APP_SYNC_URL={APP_SYNC_URL}>
        <AuthUserProvider>{children}</AuthUserProvider>
      </UrqlClientProvider>
    )
  },
)
AuthProvider.displayName = "AuthProvider"

export const useAuth = () => {
  return useContext(AuthUserContext)
}
export default SessionProvider
