import { graphql } from "../generated"

export const listFeatureFlags = graphql(`
  query listFeatureFlags {
    getFeatureFlags
  }
`)

export const productForm = graphql(`
  query productForm(
    $certificateType: CertificateType
    $country: String
    $commodity: String
  ) {
    productForm(
      input: {
        certificateType: $certificateType
        commodity: $commodity
        country: $country
      }
    ) {
      type
      required
      readonly
      numberOfLines
      nested {
        type
        id
        collection
      }
      label
      id
      options {
        value
        label
      }
      description
      collection
      numberConstrain {
        step
        min
        max
      }
      filter {
        attribute
        value
      }
    }
  }
`)

export const queryExportCertificateBase = graphql(`
  query queryExportCertificateBase($id: ID!) {
    exportCertificateRequest(id: $id) {
      id
      commodity
      certificateType
      destination {
        id
      }
    }
  }
`)

export const queryExportCertificateDetails = graphql(`
  query queryExportCertificateDetails($id: ID!) {
    exportCertificateRequest(id: $id) {
      id
      status
      certificateNumber
    }
  }
`)

export const queryReferencedDocument = graphql(`
  query referencedDocument($id: ID!) {
    referencedDocument(id: $id) {
      uploadInProgress
      id
      filename
    }
  }
`)

export const queryDownloadReferencedDocument = graphql(`
  query downloadSupportingDocument($id: ID!) {
    downloadReferencedDocument(id: $id) {
      documentName
      url
    }
  }
`)

export const queryProduct = graphql(`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      productNumber
      modifiable
      description
      wine {
        totalPackagedLitres
        availableLitres
        exportedLitres
        shippingFormat
        EUCategory
      }
      eligibilityStatus {
        market
        status
        expiryDate
      }
    }
  }
`)

export const queryValidate = graphql(`
  query queryValidate($id: ID!) {
    validate(id: $id) {
      error
      path
      type
    }
  }
`)

export const queryValidateProduct = graphql(`
  query queryValidateProduct($id: ID!) {
    validateProduct(id: $id) {
      error
      path
      type
    }
  }
`)

export const querySfExporter = graphql(`
  query querySfExporter($id: ID!) {
    sfExporter(id: $id) {
      addresses {
        addressType
        city
        country
        street
        postalCode
        isPrimary
      }
      nzbn
      name
      id
      contacts {
        emails {
          emailAddress
          isPrimary
        }
        name
        phones {
          telephoneNumber
          isPrimary
        }
        role
      }
      identifiers {
        value
        id
        description
      }
    }
  }
`)

export const queryAvailableProcesses = graphql(`
  query getAvailableProcesses($productId: ID!) {
    availableProcesses(productId: $productId) {
      description
      id
    }
  }
`)

export const queryProductProcessesSensoryEvaluation = graphql(`
  query queryProductProcessesSensoryEvaluation($productId: ID!) {
    productProcesses(
      input: { productId: $productId, filter: { processType: SENSORY } }
    ) {
      processId
      processType
      taskId
      attributes {
        ... on SensoryProcessRequest {
          assessmentDate
          reference
          disputed
          disputedReason
          id
          productId
          winemaker {
            personName
          }
          comment
        }
      }
    }
  }
`)

export const queryProductProcessesChemistryTest = graphql(`
  query queryProductProcessesChemistryTest($productId: ID!) {
    productProcesses(
      input: { productId: $productId, filter: { processType: CHEMISTRY } }
    ) {
      processId
      processType
      taskId
      attributes {
        ... on ChemistryTestResponse {
          status
          cancelledReason
          firstResultReportedDate
          receipt {
            laboratoryReference
            receiptDate
            sampleSuitable
            unsuitableReason
          }
          request {
            expectedAlcoholContent
            laboratory
            packagingDate
            packagingReference
            sampleCollectionDate
            sampleReference
            testRequired
            wineTestReference
          }
        }
      }
    }
  }
`)

export const queryProductProcessBottlingRuns = graphql(`
  query QueryProductProcessBottlingRuns($productId: ID!) {
    productProcesses(
      input: { productId: $productId, filter: { processType: BOTTLING_RUN } }
    ) {
      attributes {
        ... on Packaging {
          wsmp
          exportedLitres
          confirmation {
            confirmed
            disputeReason
            disputeResolution
          }
          availableLitres
          runs {
            ... on BottlingRunData {
              id
              bottleVolume
              bottlesPerCase
              cases
              date
              extraBottles
              litres
              reference
            }
          }
          status
          totalPackagedLitres
          volumeAdjustment {
            date
            litres
            name
            reason
          }
        }
      }
      confirmationTaskId
      requestTaskId
      resetTaskId
      taskId
      processType
      processId
    }
  }
`)

export const queryProductProcessFillingRuns = graphql(`
  query QueryProductProcessFillingRuns($productId: ID!) {
    productProcesses(
      input: { productId: $productId, filter: { processType: FLEXI_FILLING } }
    ) {
      attributes {
        ... on Packaging {
          wsmp
          exportedLitres
          confirmation {
            confirmed
            disputeReason
            disputeResolution
          }
          availableLitres
          runs {
            ... on FlexiFillingRunData {
              date
              id
              litres
              reference
            }
          }
          status
          totalPackagedLitres
          volumeAdjustment {
            date
            litres
            name
            reason
          }
        }
      }
      confirmationTaskId
      requestTaskId
      resetTaskId
      taskId
      processType
      processId
    }
  }
`)

export const queryProductDeclaration = graphql(`
  query queryProductDeclaration($commodity: String!) {
    productFormlet(input: { commodity: $commodity }) {
      declarations {
        id
        type
        fields {
          market {
            id
            value
            label
          }
          text {
            id
            type
            label
          }
          eligibilityCriteria {
            id
            value
          }
        }
      }
    }
  }
`)

export const queryCertificates = graphql(`
  query FindAvailableTemplates($commodity: String!, $countryCode: String!) {
    availableTemplates(commodity: $commodity, countryCode: $countryCode) {
      id
      code
      title
      exportCertificateType
      commodity
      destinationMarket
      displayedDescription
      minorVersion
      majorVersion
    }
  }
`)

export const queryProductProcessWinemaking = graphql(`
  query queryProductProcessWinemaking($id: ID!) {
    productProcesses(
      input: { filter: { processType: WINE_MAKING }, productId: $id }
    ) {
      attributes {
        ... on Winemaking {
          wsmp
          confirmations {
            status
            market
            disputedResolution
            disputedReason
            confirmed
          }
        }
      }
      confirmationTaskId
      requestTaskId
      resetTaskId
      taskId
      processType
      processId
    }
  }
`)
