import { authExchange, AuthUtilities } from "@urql/exchange-auth"
import { refocusExchange } from "@urql/exchange-refocus"
import {
  cacheExchange,
  CombinedError,
  createClient,
  fetchExchange,
  mapExchange,
  ssrExchange,
} from "@urql/next"
import { signOut } from "next-auth/react"
import { willTokenExpire } from "../../helper/sessionHelper"
import type { Session } from "../../types"

type SignOut = typeof signOut
type PromiseFn = () => Promise<Session | null>

export const isAuthError = (error: CombinedError) => {
  const errorResponse = error.response as Response
  return (
    error.graphQLErrors.some(
      (e) =>
        e.extensions?.code === "UnauthorizedException" ||
        e.extensions?.code === "FORBIDDEN",
    ) || errorResponse?.status === 401
  )
}

const operationExchange = (onAuthError: SignOut) =>
  mapExchange({
    onError(error) {
      if (isAuthError(error)) {
        if (typeof window !== "undefined") {
          onAuthError()
        }
      }
    },
  })

export const cognitoExchange = (getAuthSession: PromiseFn) =>
  authExchange(async (utilities: AuthUtilities) => {
    let session: Session | null = null
    try {
      session = (await getAuthSession()) as Session
    } catch (error) {
      console.debug("cognitoExchange error", error)
    }

    return {
      addAuthToOperation: (operation) => {
        if (!session?.token?.id_token) return operation
        return utilities.appendHeaders(operation, {
          Authorization: `Bearer ${session.token.id_token}`,
        })
      },
      willAuthError: () => {
        return willTokenExpire(session?.token?.exp as number)
      },
      didAuthError: (error) => {
        return isAuthError(error)
      },
      refreshAuth: async () => {
        session = (await getAuthSession()) as Session
      },
    }
  })
export const ssr = ssrExchange({
  isClient: typeof window === "undefined",
})
export const initClient = (getAuthSession: PromiseFn, onAuthError: SignOut) => {
  return (APP_SYNC_URL: string) => {
    return createClient({
      url: APP_SYNC_URL, // this will be replaced with APP_SYNC_URL dynamic in operationExchange during runtime
      exchanges: [
        refocusExchange(),
        cacheExchange,
        ssr,
        operationExchange(onAuthError),
        cognitoExchange(getAuthSession),
        fetchExchange,
      ],
      suspense: true,
    })
  }
}
