import {
  B2GProductInput,
  FormletField,
  FormletFieldType,
  FormletNestedFields,
} from "@tc/graphql-server"
import {
  assignInWith,
  cloneDeep,
  has,
  isNil,
  isObject,
  partialRight,
  set,
  union,
} from "lodash"
import zod from "zod"
import {
  ENTER_VALID_VALUE_MESSAGE,
  INVALID_MESSAGE,
  REQUIRED_MESSAGE,
} from "../constants"
import {
  dateInput,
  dateOutput,
  nonEmptyString,
  optionalString,
  toggleStringSchema,
} from "../validations"

export const emptyFunction = () => {}

export const downloadFile = (url: string, fileName = "") => {
  const link = document.createElement("a")
  link.href = url
  link.target = "_blank"
  if (fileName) {
    link.setAttribute("download", fileName)
  }
  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  // Clean up and remove the link
  link.parentNode?.removeChild(link)
}

export const copyToClipboard = (text: string) => {
  if (!navigator?.clipboard) {
    console.warn("Clipboard API not available")
    return false
  }
  return navigator.clipboard
    .writeText(text)
    .then(() => true)
    .catch(() => false)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Func<T = any> = (...args: T[]) => Promise<T> | T
export const pipePromises =
  (...fns: Func[]) =>
  (params?: unknown) =>
    fns.reduce((p, fn) => p.then(fn), Promise.resolve(params))

export const pipe =
  (...fns: Func[]) =>
  (initialValue: unknown) =>
    fns.reduce((result, func) => func(result), initialValue)

export const parseUrl = (url?: string) => {
  const defaultUrl = new URL("http://localhost:3000/api/auth")

  if (url && !url.startsWith("http")) {
    url = `https://${url}`
  }

  const _url = new URL(url ?? defaultUrl)
  const path = (_url.pathname === "/" ? defaultUrl.pathname : _url.pathname)
    // Remove trailing slash
    .replace(/\/$/, "")

  const base = `${_url.origin}${path}`

  return {
    origin: _url.origin,
    host: _url.host,
    path,
    base,
    toString: () => base,
  }
}

export const wait = async (ms: number) =>
  new Promise((res) => setTimeout(res, ms))

export const removeTypename = (obj: object) => {
  const str = JSON.stringify(obj)
  const removed = str.replace(/,"__typename":("[^"]*"|null)?/g, "")
  return JSON.parse(removed)
}
export type QueryObject = Record<string, unknown>

export function buildGraphQLQuery(
  obj: QueryObject,
  operationName = "queryExportCertificateRequest",
  variableName = "$id",
  operationType = "exportCertificateRequest",
): string {
  let queryString = `query ${operationName}(${variableName}: ID!) {\n  ${operationType}(${variableName.substring(1)}: ${variableName}) {\n`

  // Recursively build the query string based on the object structure
  queryString += buildFields(obj, 2)

  queryString += "  }\n}\n"

  return queryString
}

function buildFields(obj: QueryObject, indentLevel: number): string {
  let fieldsString = ""

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      // If the value is an object, recursively build fields
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        fieldsString += `${" ".repeat(indentLevel * 2)}${key} {\n`
        fieldsString += buildFields(value as QueryObject, indentLevel + 1)
        fieldsString += `${" ".repeat(indentLevel * 2)}}\n`
      } else if (Array.isArray(value)) {
        // If the value is an array, handle each element
        if (value.length === 0) {
          fieldsString += `${" ".repeat(indentLevel * 2)}${key}\n`
        } else {
          if (typeof value[0] === "object" && value[0] !== null) {
            fieldsString += `${" ".repeat(indentLevel * 2)}${key} {\n`
            for (const item of value) {
              fieldsString += `${" ".repeat((indentLevel + 1) * 2)}${buildFields(item, indentLevel + 1)}`
            }
            fieldsString += `${" ".repeat(indentLevel * 2)}}\n`
          } else {
            fieldsString += `${" ".repeat(indentLevel * 2)}${key}\n`
          }
        }
      } else {
        // If the value is a scalar, add it to the fields string
        fieldsString += `${" ".repeat(indentLevel * 2)}${key}\n`
      }
    }
  }
  return fieldsString
}

export const loadField = (
  _product: FormletField[],
  commodity: string,
  premiseId?: string,
) => {
  const _item = {
    premise: {
      id: premiseId || "",
    },
    description: "",
    commodity,
  }

  // handle fields
  for (const field of _product) {
    const fieldId = field?.id

    if (field?.type === FormletFieldType.Nested) {
      /**
       * NOTE: when field is nested, there is parent field id and nested fields
       * each nested field MIGHT have its own id
       * but the nested field id might be duplicated with their parent field id
       * if all nested fields have the same id, we need to append index to the end of id
       */
      const parentFieldId = field?.id
      const nestedFields = field?.nested || []
      // check if all nested fields have the same id
      const isAllSameId = nestedFields.every(
        (nestedField) => nestedField?.id === nestedFields[0]?.id,
      )
      for (const [i, nestedField] of nestedFields.entries()) {
        const id = nestedField?.id
        /**
         * union the parentFieldId and nestedField.id to remove duplicated part in id
         * e.g. parentFieldId is "finalProcessor.address"
         * nestedField.id is "finalProcessor.address.city"
         */
        const path = union(parentFieldId?.split("."), id?.split("."))
        if (isAllSameId) {
          /**
           * append index to the end of id if all nested fields have the same id
           * e.g.
           * physicalPackage.additionalInformation.0
           * physicalPackage.additionalInformation.1
           */
          path.push(i.toString())
        }
        set(_item, path, "")
      }
      continue
    }

    if (
      field?.type === FormletFieldType.Radio ||
      field?.type === FormletFieldType.Search ||
      field?.type === FormletFieldType.ShortTextArray
    ) {
      set(_item, fieldId, undefined)
      continue
    }

    if (field?.type === FormletFieldType.Party) {
      const path = union(fieldId?.split("."), ["partyId"]).join(".")
      set(_item, path, "")
      continue
    }
    if (fieldId) {
      set(_item, fieldId, "")
      continue
    }
  }
  return _item
}

export const isValidNumberString = (
  value: string,
  required?: boolean,
  decimal = 3,
): boolean => {
  if (!required && !value) {
    return true
  }

  const numberRegex = `^\\d*\\.?\\d{0,${decimal}}$`
  const regex = new RegExp(numberRegex)
  return regex.test(value)
}

export const isValidDateTimeString = (value: string) => {
  return !!value.match(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
  )
}

export const hasField =
  (fields: object = {}) =>
  (name: string) =>
    has(fields, name)

export const getFormletFieldsCollectionSchema = (
  formletFields: FormletField[],
): zod.ZodSchema => {
  const productSchema = getFormletFieldsSchema(formletFields)
  const itemsSchema = zod.array(
    zod.object({
      product: productSchema,
    }),
  )
  return itemsSchema
}

export const getWineItemCollectionSchema = () => {
  return zod.array(
    zod.object({
      product: zod.object({}).passthrough(),
    }),
  )
}

export const getFormletFieldsSchema = (
  formletFields: FormletField[],
): zod.ZodSchema<B2GProductInput> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schemaObj = {} as Record<keyof B2GProductInput, any>

  for (const field of formletFields) {
    const fieldId = field?.id
    let parentId
    if (fieldId.includes(".")) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[, parentId] = splitParentAndChildIds(fieldId) as string[]
    }
    const childSchema = getFormletFieldSchema(field, parentId)
    if (schemaObj[(parentId || fieldId) as keyof B2GProductInput]) {
      schemaObj[(parentId || fieldId) as keyof B2GProductInput] = (
        schemaObj[
          (parentId || fieldId) as keyof B2GProductInput
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as zod.ZodObject<any>
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .merge(childSchema as zod.ZodObject<any>)
        .passthrough()
    } else {
      schemaObj[(parentId || fieldId) as keyof B2GProductInput] = childSchema
    }
  }

  return zod.object(schemaObj).passthrough() as zod.ZodSchema<B2GProductInput>
}

export const getFormletFieldSchema = (
  formletField: FormletField,
  parentId?: string,
) => {
  const fieldId = formletField.id
  switch (formletField.type) {
    case FormletFieldType.ShortText:
    case FormletFieldType.LongText:
    case FormletFieldType.MultilineText:
    case FormletFieldType.Search:
    case FormletFieldType.Select:
    case FormletFieldType.Party:
    // TODO confirm radio type schema
    // eslint-disable-next-line no-fallthrough
    case FormletFieldType.Radio:
      return checkAndGetPossibleMultiLevelSchemaObject(
        fieldId,
        nonEmptyString,
        formletField.required as boolean,
        optionalString,
        parentId,
      )
    case FormletFieldType.Number: {
      let numberScheme = zod.coerce.number()
      if (formletField.numberConstrain) {
        numberScheme = numberScheme.multipleOf(
          formletField.numberConstrain.step,
        )
        if (formletField.numberConstrain.min) {
          numberScheme = numberScheme.min(formletField.numberConstrain.min)
        }
        if (formletField.numberConstrain.max) {
          numberScheme = numberScheme.max(formletField.numberConstrain.max)
        }
      } else {
        numberScheme = zod.coerce.number().int()
      }
      return checkAndGetPossibleMultiLevelSchemaObject(
        fieldId,
        zod
          .union([zod.number(), zod.string(), zod.literal(undefined)])
          .superRefine((value, ctx) => {
            if (!value) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: REQUIRED_MESSAGE,
              })
              return
            }
            const parseResult = numberScheme.safeParse(value)
            if (!parseResult.success) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: ENTER_VALID_VALUE_MESSAGE,
              })
              return
            }
          }),
        formletField.required as boolean,
        zod
          .union([zod.number(), zod.string(), zod.literal(undefined)])
          .superRefine((value, ctx) => {
            if (!value) {
              return
            }
            const parseResult = numberScheme.safeParse(value)
            if (!parseResult.success) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: ENTER_VALID_VALUE_MESSAGE,
              })
              return
            }
          }),
        parentId,
      )
    }
    case FormletFieldType.Date:
      return checkAndGetPossibleMultiLevelSchemaObject(
        fieldId,
        zod.union([dateInput, dateOutput]),
        formletField.required as boolean,
        undefined,
        parentId,
      )
    case FormletFieldType.ShortTextArray: {
      const result = toggleStringSchema(!formletField.required)
      return checkAndGetPossibleMultiLevelSchemaObject(
        fieldId,
        zod
          .union([
            zod.literal(undefined),
            zod.literal(""),
            zod.array(zod.string()).min(1),
          ])
          .transform((input) => {
            if (!input) {
              return []
            }

            if (Array.isArray(input)) {
              return input.filter((v) => !!v)
            }

            return input
          })
          .superRefine((value, ctx) => {
            if (value.length < 1) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: REQUIRED_MESSAGE,
              })
              return
            }
          }),
        formletField.required as boolean,
        zod
          .union([
            zod.literal(undefined),
            zod.literal(""),
            zod.array(result).nullish(),
          ])
          .transform((value) => {
            if (!value) {
              return []
            }

            return value
          }),
        parentId,
      )
    }
    case FormletFieldType.Nested: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const schemaObj = {} as Record<string, any>

      // TODO confirm if nested parent field id could contain '.', at the moment it assums it doesn't
      for (const nestedField of formletField.nested as FormletNestedFields[]) {
        const trimmedId = trimFormletNestedFieldId(nestedField.id, fieldId)
        const theNestedField = cloneDeep(nestedField) as unknown as FormletField
        theNestedField.id = trimmedId
        theNestedField.required = formletField.required

        let parentId
        if (trimmedId.includes(".")) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;[, parentId] = splitParentAndChildIds(trimmedId) as string[]
        }

        const innerFieldSchema = getFormletFieldSchema(theNestedField, parentId)
        schemaObj[parentId || trimmedId] = innerFieldSchema
      }
      // console.log("nested field schema ", zod.object(schemaObj))
      return zod.object(schemaObj)
    }

    default:
      return undefined
  }
}

const trimFormletNestedFieldId = (
  fieldId: string,
  parentId: string,
): string => {
  return fieldId.replace(new RegExp("^" + parentId + "."), "")
}

const checkAndGetPossibleMultiLevelSchemaObject = (
  fieldId: string,
  coreSchema: zod.ZodSchema,
  isRequired?: boolean,
  coreOptionalSchema?: zod.ZodSchema,
  parentId?: string,
) => {
  if (fieldId.includes(".")) {
    return getMultiLevelSchemaObject(
      fieldId,
      coreSchema,
      isRequired,
      coreOptionalSchema,
      parentId,
    )
  } else {
    return isRequired ? coreSchema : coreOptionalSchema || coreSchema.optional()
  }
}

const getMultiLevelSchemaObject = (
  fieldId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // parentSchemaObject: Record<string, any>,
  coreSchema: zod.ZodSchema,
  isRequired?: boolean,
  coreOptionalSchema?: zod.ZodSchema,
  parentId?: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let subSchemaObj = {} as Record<string, any>
  // nested field id could contain parent's id. e.g. 'physicalPackage.typeCode.code' where 'physicalPackage' is the parent id
  const id = parentId ? trimFormletNestedFieldId(fieldId, parentId) : fieldId
  // after trimming if the id contains '.' then it need to be turned into multi level schema object
  const idParts = id.split(".")
  // build the schema object from the inner to outter (i.e. in reverse order)
  for (const [index, idPart] of idParts.reverse().entries()) {
    let subSchema: zod.ZodSchema

    if (index === 0) {
      // subSchema = getFormletFieldSchema(field) as zod.ZodSchema
      subSchema = (
        isRequired ? coreSchema : coreOptionalSchema || coreSchema?.optional()
      ) as zod.ZodSchema
    } else {
      // generate schema based on the schema generated in previous round
      subSchema = zod.object(subSchemaObj) as zod.ZodSchema
    }

    subSchemaObj = {}
    subSchemaObj[idPart] = subSchema
  }
  return zod.object(subSchemaObj)
}

export const splitParentAndChildIds = (id: string): string[] | null => {
  return id.match(/^([^.]+)\.(.+)$/)
}

export const getRequestUrl = (
  requestId?: string,
  commodity?: string,
  destination?: string,
  certType?: string,
) => {
  if (requestId) {
    return `/export-request/request/${requestId}`
  } else {
    return `/export-request/request/start/${commodity}/${destination}/${certType}`
  }
}

const deepMerge = (objValue: unknown, srcValue: unknown) => {
  if (isObject(objValue)) {
    return assignInWith(objValue, srcValue, deepMerge)
  }
  if (srcValue === "") return objValue
  if (isNil(srcValue)) return objValue
  return srcValue
}
type MergeFunction = (objValue: object, srcValue: object) => unknown
export const smartMerge: MergeFunction = partialRight(assignInWith, deepMerge)
